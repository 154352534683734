
import {defineComponent, onMounted, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Chart from "@/views/chart/Chart.vue";
import {ChartCode, ChartType} from "@/core/config/Constant";
import BaseClientSelect from "@/views/company/BaseClientSelect.vue";

export default defineComponent({
  name: "CaseManagement",
  components: {BaseClientSelect,  Chart},
  setup() {
    const clientId = ref('');
    const mounted = ref(false)
    onMounted(() => {
      mounted.value = true;
      setCurrentPageBreadcrumbsWithParams("Case Management Dashboard", [])
    })

    return{
      mounted,
      clientId,
      ChartCode,
      ChartType,
    }
  }
})
